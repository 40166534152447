import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private isHandlingError = false;

  constructor(private authService: AuthService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.url.endsWith('/refresh-token')) {
      return next.handle(request);
    }

    if (this.authService.isTokenExpired() && this.authService.isLoggedIn()) {
      return this.authService.refreshToken().pipe(
        switchMap((response) => {
          // Garantir que o novo token está sendo usado
          const user = this.authService.getLoggedUser();
          if (user) {
            request = request.clone({
              setHeaders: { Authorization: `Bearer ${user.token}` },
            });
          }
          return next.handle(request);
        }),
        catchError((error) => this.handleError(error)),
      );
    } else {
      return next
        .handle(this.addTokenToRequest(request))
        .pipe(catchError((error) => this.handleError(error)));
    }
  }

  private addTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    if (!request.url.includes('s3')) {
      const user = this.authService.storage.getLocalUser();
      const isFormData = request.body instanceof FormData;
  
      if (user) {
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${user.token}`,
            ...(isFormData ? {} : { 'Content-Type': 'application/json;charset=UTF-8' }),
          },
        });
      }
    }
    return request;
  }  

  private handleError(error: any) {
    if (
      error instanceof HttpErrorResponse &&
      (error.status === 401 || error.status === 403 || error.status === 400)
    ) {
      if (!this.isHandlingError) {
        this.isHandlingError = true;
        this.authService.handleLogin(this.router.url);
      }
    }
    return throwError(error);
  }
}
